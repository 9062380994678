import React from 'react';

import AuthLayout from '@src/layouts/Auth';
import HomeTemplate from '@src/templates/Home';

const Homepage = () => <HomeTemplate />;

Homepage.layout = AuthLayout;

export default Homepage;
