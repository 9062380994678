import {css, Theme} from '@emotion/react';
import styled from '@emotion/styled';

import {ButtonSize, ButtonVariant} from './Button';

interface WrapperProps {
  fullWidth: boolean;
  hasIcon: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
}

const wrapperModifiers = {
  fullWidth: () => css`
    width: 100%;
  `,

  withIcon: () => css`
    align-items: center;
    display: inline-flex;
    justify-content: center;

    svg {
      height: 20px;
      width: 20px;
    }

    svg + span {
      margin-left: 10px;
    }
  `,
};

const variantModifiers = {
  primary: (theme: Theme) => css`
    background-color: ${theme.colors.brandColor1};
    border-color: ${theme.colors.brandColor1};
    color: ${theme.colors.white};

    &:hover {
      background-color: ${theme.colors.brandColor1Darkened};
      border-color: ${theme.colors.brandColor1Darkened};
    }

    &:disabled {
      background-color: ${theme.colors.gray3};
      border-color: ${theme.colors.gray3};
      color: ${theme.colors.white};
    }
  `,

  secondary: (theme: Theme) => css`
    background-color: ${theme.colors.red};
    border-color: ${theme.colors.red};
    color: ${theme.colors.white};

    &:hover {
      background-color: ${theme.colors.redDarkened};
      border-color: ${theme.colors.redDarkened};
    }

    &:disabled {
      background-color: ${theme.colors.gray3};
      border-color: ${theme.colors.gray3};
      color: ${theme.colors.white};
    }
  `,

  alternate: (theme: Theme) => css`
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.brandColor1};
    color: ${theme.colors.brandColor1};

    &:hover {
      background-color: ${theme.colors.brandColor1Light};
    }

    &:disabled {
      background-color: ${theme.colors.gray1};
      border-color: ${theme.colors.gray3};
      color: ${theme.colors.gray3};
    }
  `,

  alternate2: (theme: Theme) => css`
    background-color: ${theme.colors.brandColor1Light};
    border-color: ${theme.colors.brandColor1Light};
    color: ${theme.colors.brandColor1};

    &:hover {
      filter: brightness(0.95);
    }

    &:disabled {
      background-color: ${theme.colors.gray1};
      border-color: ${theme.colors.gray1};
      color: ${theme.colors.gray3};
      filter: brightness(1);
    }
  `,
};

const sizeModifiers = {
  small: (theme: Theme) => css`
    padding: 8px;
    font-size: ${theme.font.sizes.xsmall};
  `,

  medium: (theme: Theme) => css`
    padding: 16px;
    font-size: ${theme.font.sizes.small};
  `,

  large: (theme: Theme) => css`
    padding: 21px;
    font-size: ${theme.font.sizes.small};
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({theme, fullWidth, size, hasIcon, variant}) => css`
    border: 1px solid;
    border-radius: 16px;
    cursor: pointer;
    display: block;
    font-weight: ${theme.font.weight.bold};
    height: auto;
    text-align: center;
    text-decoration: none;
    transition: background-color ${theme.transition.default}, opacity ${theme.transition.default};
    width: fit-content;
    font-family: ${theme.font.family.averta};

    span {
      vertical-align: middle;
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      cursor: not-allowed;
    }

    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${hasIcon && wrapperModifiers.withIcon()};
    ${sizeModifiers[size](theme)};
    ${variantModifiers[variant](theme)};
  `}
`;
