import React, {forwardRef, AnchorHTMLAttributes, ButtonHTMLAttributes} from 'react';

import {isDevelopment} from '@src/utils/environment';

import * as S from './Button.styles';

export type ButtonTypes = AnchorHTMLAttributes<HTMLAnchorElement> & ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'primary' | 'secondary' | 'alternate' | 'alternate2';

export interface ButtonProps extends ButtonTypes {
  fullWidth?: boolean;
  asLink?: boolean;
  icon?: React.ReactNode;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const Button = forwardRef(
  (
    {children, fullWidth = false, asLink = false, size = 'medium', variant = 'primary', icon, ...props}: ButtonProps,
    ref?: React.Ref<HTMLButtonElement & HTMLAnchorElement>
  ) => (
    <S.Wrapper
      className="ButtonStyles__Wrapper"
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      hasIcon={!!icon}
      ref={ref}
      {...(asLink ? {as: 'a'} : {})}
      {...props}>
      {icon}
      {!!children && <span>{children}</span>}
    </S.Wrapper>
  )
);

if (isDevelopment) {
  Button.displayName = 'Button';
}

export default Button;
