import React, {useCallback, useEffect} from 'react';

import AuthTemplate from '@src/templates/Auth';
import LandingPageSection from '@src/components/molecules/LandingPageSection';
import useAuth from '@src/hooks/useAuth';
import HomeIllustrationSrc from '@public/img/illustrations/home.svg';

const HomeTemplate = () => {
  const {loadUserWhenHomePageMounted, logOut} = useAuth();

  const checkAuthentication = useCallback(async () => {
    try {
      await loadUserWhenHomePageMounted();
    } catch (e) {
      console.warn(e);
      logOut();
    }
  }, [loadUserWhenHomePageMounted, logOut]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return (
    <AuthTemplate
      illustrationSrc={HomeIllustrationSrc}
      illustrationAlt="A teacher working on her computer with a whiteboard with charts on it behind her.">
      <LandingPageSection />
    </AuthTemplate>
  );
};

export default HomeTemplate;
