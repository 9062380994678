import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';

import * as S from './AuthHeaderNav.styles';

const AuthHeaderNav = () => {
  const router = useRouter();

  const isLandingPage = /^\/$/.test(router.route);

  return (
    <S.Wrapper>
      <Link href={'/'} passHref>
        <S.Logo>
          <Image
            src="/img/logo.svg"
            alt="Riiid for Classrooms logo"
            width={100}
            height={30}
            placeholder="blur"
            blurDataURL="/img/logo.svg"
          />
        </S.Logo>
      </Link>

      {isLandingPage && (
        <S.AuthNav>
          <Link href="/signin" passHref>
            <S.LinkLabel>Sign In</S.LinkLabel>
          </Link>
          <Link href="/signup" passHref>
            <S.LinkLabel>Sign Up</S.LinkLabel>
          </Link>
        </S.AuthNav>
      )}
    </S.Wrapper>
  );
};

export default AuthHeaderNav;
