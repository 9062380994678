import styled from '@emotion/styled';

export const Wrapper = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  height: 100%;
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: right;
  height: 610px;
`;
