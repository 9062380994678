import Image from 'next/image';
import React from 'react';

import * as S from './styles';

interface AuthTemplateProps {
  illustrationSrc: string;
  illustrationAlt: string;
  children: React.ReactNode;
}

const AuthTemplate = ({illustrationSrc, illustrationAlt, children}: AuthTemplateProps) => (
  <S.Wrapper>
    {children}
    <S.IllustrationWrapper>
      <Image src={illustrationSrc} alt={illustrationAlt} />
    </S.IllustrationWrapper>
  </S.Wrapper>
);

export default AuthTemplate;
