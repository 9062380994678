import React from 'react';
import Link from 'next/link';

import Button from '@src/components/atoms/Button';

import * as S from './LandingPageSection.styles';

const LandingPageSection = () => {
  return (
    <S.Wrapper>
      <S.Title>A platform that puts teachers first</S.Title>
      <Link passHref href="/signin">
        <Button fullWidth asLink>
          Get started
        </Button>
      </Link>
    </S.Wrapper>
  );
};

export default LandingPageSection;
