import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 40px;
  grid-gap: 40px;
  padding: 60px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;
