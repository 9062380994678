import React from 'react';

import AuthHeaderNav from '@src/components/molecules/AuthHeaderNav';

import * as S from './styles';

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout = ({children}: AuthLayoutProps) => (
  <S.Wrapper>
    <AuthHeaderNav />
    {children}
  </S.Wrapper>
);

export default AuthLayout;
