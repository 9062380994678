import {css} from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  align-content: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Logo = styled.a`
  min-width: 112px;
  width: auto;
`;

export const AuthNav = styled.nav`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const LinkLabel = styled.a`
  ${({theme}) => css`
    color: ${theme.colors.gray4};
    cursor: pointer;
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.weight.normal};
    white-space: nowrap;
    width: 100%;
  `}
`;
