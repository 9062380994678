import {useContext} from 'react';

import {AuthContext, AuthContextProps} from '@src/contexts/AuthContext';

const useAuth = (): AuthContextProps => {
  const authContext = useContext(AuthContext);

  return authContext;
};

export default useAuth;
