import {css} from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 40px;
  width: 360px;
`;

export const Title = styled.h1`
  ${({theme}) => css`
    margin-bottom: 40px;
    color: ${theme.colors.gray8};
    font-size: 30px;
    font-weight: ${theme.font.weight.normal};
  `}
`;
